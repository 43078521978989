<template>
  <div class="home" :class="active+'-active'">
    <side-bar>
      <portrait :name="name" :working="working" :job="job" :photo="photo" />
      <basic-information :address="address" :graduated="graduated" />
      <skill v-if="skills" :skills="skills" />
      <ability v-if="abilities" :abilities="abilities" />
      <contract :data="contract" />
    </side-bar>

    <prime>
      <about-me :contents="aboutMe" />
      <project-list v-if="projects" :projects="projects" />
      <career-list :data="careers" />
    </prime>
  </div>
</template>

<script>
import Prime from '@/components/Prime.vue';
import AboutMe from '@/components/AboutMe.vue';
import ProjectList from '@/components/ProjectList.vue';
import CareerList from '@/components/CareerList.vue';

import SideBar from '@/components/SideBar.vue';
import Portrait from '@/components/Portrait.vue';
import BasicInformation from '@/components/BasicInformation.vue';
import Skill from '@/components/Skill.vue';
import Ability from '@/components/Ability.vue';
import Contract from '@/components/Contract.vue';

import info from '@/infos/yochia.json';

export default {
  name: 'home',
  components: {
    Prime,
    AboutMe,
    ProjectList,
    CareerList,
    SideBar,
    Portrait,
    BasicInformation,
    Skill,
    Ability,
    Contract
  },
  data () {
    return {
      ...info
    };
  },
  computed: {
    active() {
      return this.$store.state.active;
    }
  }
};
</script>

<style scoped>
.home {
  max-width: 1200px;
  margin: auto;
  padding-top: 50px;
}

@media (max-width: 767px) {
  .home.prime-active .aside {
    display: none;
  }
  .home.aside-active .prime {
    display: none;
  }
}
</style>
