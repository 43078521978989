<template>
  <div id="app">
    <navigation-bar :switchable="this.$route.name === 'home'" :active="this.$store.state.active" @update:active="onActiveChanged" />

    <transition name="fade" mode="out-in">
      <router-view/>
    </transition>
    
    <footer>
      Presented By Yochia @2020 ·
      <a href="resources/Yochia-Resume.pdf" target="_blank">获取PDF版本</a> ·
      <router-link to="/about">关于本页</router-link>
    </footer>
  </div>
</template>

<script>
import NavigationBar from "@/components/NavigationBar.vue";

export default {
  components: {
    NavigationBar
  },
  methods: {
    onActiveChanged(input) {
      this.$store.commit('switchHome', input);
    }
  }
}
</script>


<style>
#app {
  font-family: "Microsoft Yahei", 微软雅黑, Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
}

a {
  color: lightskyblue;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}
.fade-enter,
.fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
footer {
  text-align: center;
  padding: 10px 10px;
  font-size: 12px;
  color: darkgray;
}

footer a {
  color: darkgray;
  text-decoration: none;
}

</style>
