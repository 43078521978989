<template>
  <div class="project">
    <prime-title :title="detail ? '项目经历' : '参与项目'" />
    <project 
      v-for="(project, index) of projects" :key="index"
      v-show="!project.hidden || detail"
      :logo="project.logo" :title="project.title" :subtitle="project.subtitle"
      :image="project.image" :description="project.description" :detail="project.detail"
      :isDetail="detail"
    />
  </div>
</template>

<script>
import PrimeTitle from "@/components/PrimeTitle.vue";
import Project from "@/components/Project.vue";

export default {
  name: "project-list",
  props: {
    projects: {
      type: Array
    },
    detail: {
      type: Boolean,
      default: false
    }
  },
  components: { PrimeTitle, Project }
};
</script>

<style scoped>
</style>