<template>
  <div class="home" :class="active+'-active'">
    <prime>
      <project-list v-if="projects" :projects="projects" detail />
    </prime>
  </div>
</template>

<script>
import info from '@/infos/yochia.json';
import Prime from '@/components/Prime.vue';
import ProjectList from '@/components/ProjectList.vue';

export default {
  name: 'project',
  components: {
    Prime,
    ProjectList
  },
  data () {
    return {
      ...info
    };
  },
  computed: {
    active() {
      return this.$store.state.active;
    }
  }
};
</script>

<style scoped>
.home {
  max-width: 1200px;
  margin: auto;
  padding-top: 50px;
}
.prime {
  max-width: 100%;
  color: #666;
}

@media (max-width: 767px) {
  .project.prime-active .aside {
    display: none;
  }
  .project.aside-active .prime {
    display: none;
  }
}
</style>
